import React from "react";
import Logo from "components/Logo";

import { Navigate } from "react-router-dom";

const Login = () => {
  const pharmaforce = sessionStorage.getItem("pharmaforce");
  if (pharmaforce) {
    return <Navigate to="/file-source" />;
  }

  return (
    <div className="h-90vh d-flex align-items-center justify-content-center">
      <div className="text-center">
        <Logo height={50} />
      </div>
    </div>
  );
};

export default Login;
