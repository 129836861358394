import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Button, FormGroup, Label, Row, Col, Modal, ModalHeader, ModalBody, InputGroup, InputGroupText, Input, Navbar, Nav, NavItem } from "reactstrap";
import ReactStrapTable from 'components/reactStrapTable';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import useAxios from '../../hooks/useAxios';
import { dateFormate, cellRendererCheck, currencyFormat } from 'utils/columns';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import useContextService from 'hooks/useContextService';
import { Link, useLocation } from "react-router-dom";
import FormikInputField from 'components/common/Fields/FormikInputField';
import { toast } from 'react-toastify';
import { CancelIcon } from '../../assets/icon/cross.png'
import { faPlus, faClose } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ViewClaimsTable from './ViewClaimTable/viewClaimTable';
import { onExport } from 'utils/helper';
import delIcon from 'assets/icon/icon-icon-options-delete.svg'
import delhoverIcon from 'assets/icon/icon-icon-options-delete-hover.svg'
import HoverImage from 'react-hover-image/build';
import { default as ReactSelect } from "react-select";
import { Option, LimitedChipsContainer } from 'components/Options';
const validationSchema = Yup.object({
});
let pharmaciesData = [];
const repriceTypes = [{ id: 'PHN', value: 'Pharmacy Network ID' },
{ id: 'CUST', value: 'Customized Model' }];
const configCodes = [
  // { id: '340BPD', value: '340B Price' },
  { id: 'WACPD', value: 'WAC Price' },
  { id: 'AWPPD', value: 'AWP Price' },
  { id: 'NADACBPD', value: 'NADACB Price' },
  { id: 'NADACGPD', value: 'NADACG Price' },
  { id: 'VAWPPD', value: 'Vaccine AWP Price' },
  { id: 'DF', value: 'Dispensing Fee' },
  { id: 'NADACBDF', value: 'NADACB Dispensing Fee' },
  { id: 'NADACGDF', value: 'NADACG Dispensing Fee' },
  { id: 'CDF', value: 'Compound Dispensing' },
];
const drugTypes = [
  { id: '*', value: 'ALL' },
  { id: 'SSB', value: 'SSB' },
  { id: 'MSB', value: 'MSB' },
  { id: 'Generic', value: 'Generic' }
];
const controlledSubstance = [
  { id: '*', value: 'ALL' },
  { id: 'C2', value: 'C2' },
  { id: 'C3', value: 'C3' },
  { id: 'C4', value: 'C4' },
  { id: 'C5', value: 'C5' }
];
const specialtyDrug = [
  { id: 'Y', value: 'YES' },
  { id: 'N', value: 'NO' },
];

let initialFilters = {
  currentPage: 1,
  pageSize: 10,
  orderBy: '',
  order: '',
  filters: [],
};

const ViewClaims = ({
  rowClaimSourceData,
  toggle,
  claimSourceRefetch
}) => {
  const API = useAxios();
  const [loading, setLoading] = useState(false);
  const [claimHistory, setClaimHistory] = useState([]);
  const [state, updateState] = useState({
    initialValues: {},
    rows: [],
    data: [],
    records: 0,
    reset: false,
  });
  const [selectedRepriceType, setSelectedRepriceType] = useState('');
  const [pharmacyNetworks, setPharmacyNetworks] = useState([]);
  const [selectedPharmacyNetworkID, setSelectedPharmacyNetworkID] = useState({ optionPhSelected: '-1' });
  const [selectedTab, setSelectedTab] = useState('CNFG');
  const [selectedDaysSupply, setSelectedDaysSupply] = useState('');
  const [selected340bPrice, setSelected340bPrice] = useState('N');
  const [selectedWACPrice, setSelectedWACPrice] = useState('N');
  const [selectedAWPPrice, setSelectedAWPPrice] = useState('N');
  const [selectedVAWPPrice, setSelectedVAWPPrice] = useState('N');
  const [selectedFULPrice, setSelectedFULPrice] = useState('N');
  const [selectedNADACBPrice, setSelectedNADACBPrice] = useState('N');
  const [selectedNADACGPrice, setSelectedNADACGPrice] = useState('N');
  const [selectedMACPrice, setSelectedMACPrice] = useState('N');
  const [selectedUCPrice, setSelectedUCPrice] = useState('Y');
  const [selectedIncentiveFee, setSelectedIncentiveFee] = useState('');
  const [selectedAllowRetailSpecialty, setSelectedAllowRetailSpecialty] = useState('Y');
  const [selectedAllowCompound, setSelectedAllowCompound] = useState('Y');
  const [selectedAllowCOB, setSelectedAllowCOB] = useState('Y');
  const [selectedAllowVaccineDrug, setSelectedAllowVaccineDrug] = useState('Y');
  const [selectedConfigCode, setSelectedConfigCode] = useState('');
  const [isConfigPriceSelected, setConfigPriceSelected] = useState(false);
  const [selectedDrugType, setSelectedDrugType] = useState('');
  const [selectedAmountDaysSupply, setSelectedAmountDaysSupply] = useState('');
  const [selectedAmountSpecialtyDrug, setselectedAmountSpecialtyDrug] = useState('N');
  const [selectedFlatAmount, setSelectedFlatAmount] = useState('');
  const [selectedPercentage, setSelectedPercentage] = useState('');
  const [selectedDiscountMarkup, setSelectedDiscountMarkup] = useState('');
  const [selectedControlledSubstance, setSelectedControlledSubstance] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedNetworkIDS, setSelectedNetworkIDS] = useState(false);
  useEffect(() => {
    initialFilters = {
      currentPage: 1,
      pageSize: 10,
      orderBy: 'modifiedDate',
      order: 'desc',
      filters: [],
    };
    setState({ data: [], records: 0 });
    if (rowClaimSourceData) {
      fetchViewClaimsData(initialFilters);
    }

  }, []);

  const setState = (obj) => updateState((pre) => ({ ...pre, ...obj }));

  const { rows, initialValue } = state;


  const formikRef = useRef();
  useEffect(() => {
    setState({ loading: true });
    pharmaciesData = [];
  }, []);

  const {
    states: { fileSource },
    setStates,
    resetStates,
  } = useContextService();

  const { searchTerms } = fileSource;

  let {
    selectedReprice
  } = searchTerms;
  const [selectedNetworkId,SetSelectedNetorkId]=useState([])




  const handleSubmit = async (val, actions) => {
    const { setSubmitting } = actions;
    const values = cloneDeep(val);
    setSubmitting(false);
  };

  const handleRepriceSubmit = async () => {

   
    if (selectedRepriceType == 'CUST') {
      let configurations = {};
      let customConfigurationModel = {
        //pharmacyNetworkID: selectedPharmacyNetworkID,
        claimSourceDetailsID: rowClaimSourceData.claimSourceDetailsId,
        //repriceMethod: rowClaimSourceData?.repricingMethodId,
        AmountConfigurations: [],
      };
      configurations.dsl = selectedDaysSupply;
      //configurations.p340B = selected340bPrice;
      configurations.wacp = selectedWACPrice;
      configurations.awp = selectedAWPPrice;
      configurations.fulp = selectedFULPrice;
      configurations.nadacbp = selectedNADACBPrice;
      configurations.nadacgp = selectedNADACGPrice;
      configurations.macp = selectedMACPrice;
      configurations.usctp = selectedUCPrice;
      configurations.icf = selectedIncentiveFee;
      configurations.asr = selectedAllowRetailSpecialty;
      configurations.acdp = selectedAllowCompound;
      configurations.acobp = selectedAllowCOB
      configurations.avd = selectedAllowVaccineDrug;
      configurations.vawp =selectedVAWPPrice;

      customConfigurationModel.Configurations = configurations;
      customConfigurationModel.AmountConfigurations = rows;
      if (customConfigurationModel) {
        try {
          const data = await API.post(
            `/ClaimAnalyzer/repriceCustomModel`,
            customConfigurationModel
          );
          if (data?.data) {
            toast.error('Repricing is in-progress please wait');
            setLoading(false);
            toggle();
            claimSourceRefetch(true);
          }
        } catch (err) {
          setLoading(false);
        }
      }
    }
    else if (selectedReprice = 'PHN') {
      let filters = {
        pharmacyNetworkID: selectedPharmacyNetworkID.optionPhSelected.map(item=>item["value"]),
        claimSourceDetailsID: rowClaimSourceData.claimSourceDetailsId
      };
      if (filters) {
        try {
          const data = await API.post(
            `/ClaimAnalyzer/repricePharmacyNetworkID`,
            filters
          );
          if (data?.data) {
            toast.error('Repricing is in-progress please wait');
            setLoading(false);
            toggle();
            claimSourceRefetch(true);
          }
        } catch (err) {
          setLoading(false);
        }
      }
    }
  };
  const handleReset = () => {
    setSelectedRepriceType('');
    setSelectedPharmacyNetworkID('');
    handleConfigurationsReset();
    handleAmountsReset();
    state.rows = [];
  }
  const handleConfigurationsReset = () => {
    setSelectedDaysSupply('');
    setSelected340bPrice('N');
    setSelectedWACPrice('N');
    setSelectedAWPPrice('N');
    setSelectedFULPrice('N');
    setSelectedNADACBPrice('N');
    setSelectedNADACGPrice('N');
    setSelectedMACPrice('N');
    setSelectedUCPrice('Y');
    setSelectedIncentiveFee('');
    setSelectedAllowRetailSpecialty('Y');
    setSelectedAllowCompound('Y');
    setSelectedAllowCOB('Y');
    setSelectedAllowVaccineDrug('Y');
    setSelectedVAWPPrice('N');
  }
  const handleAmountsReset = () => {
    setSelectedAllowVaccineDrug('Y');
    setSelectedConfigCode('');
    setConfigPriceSelected(false);
    setSelectedDrugType('');
    setSelectedAmountDaysSupply('');
    setselectedAmountSpecialtyDrug('N');
    setSelectedFlatAmount('');
    setSelectedPercentage('');
    setSelectedDiscountMarkup('');
    setSelectedControlledSubstance('');
    setErrorMessage('');
  }

  const handleRepriceMethodChange = async ({ target: { value } }, setFieldValue) => {
    setSelectedRepriceType(value);
    if (value == 'PHN') {
      try {
        pharmaciesData = [];
        const { data } = await API.post('/PharmacyNetwork/getPBMNetworks', {
          pbmAPI: true,
        });
        setLoading(false);
        if (data) {
          data.map((pNetwork) => {
            pNetwork.pharmacyNetworkData = `${pNetwork.pharmacyNetworkCode}-${pNetwork.pharmacyNetworkDesc}`;
          });
          if (data) {
            for (var x in data) {
                pharmaciesData.push({ "label":`${ data[x].pharmacyNetworkCode}-${ data[x].pharmacyNetworkDesc}`,"value":data[x].pbmpharmacyNetworkId
                });
            }
          }
          setPharmacyNetworks(data);
        }
      } catch (err) {
        setLoading(false);
      }
    }
  }
  // const handlePharmacyNetworkChange = ({ target: { value } }, setFieldValue) => {
  //   setSelectedPharmacyNetworkID(value);
  // };
  const handlePharmacyNetworkChange = (selected, setFieldValue, cp) => {
    if (selected) {
      setSelectedPharmacyNetworkID({
        optionPhSelected: selected
        });
        let val = selected.map(item=>item["value"]);
        console.log(val)
        if(val.length>0){
        SetSelectedNetorkId(val)
        setSelectedNetworkIDS(true);
        }
        else
        setSelectedNetworkIDS(false);
        // setFieldValue('pharmacyID', val);
        // setFieldValue('PharmacyId', selected);
        //addPharmacyConfig.pharmacy = selected;
    }

};
  const updateRepriceType = (repriceType) => {
    setStates('repriceType', repriceType);
  };

  const handleAmountSubmit = () => {
    setErrorMessage('');

    if (!selectedAmountDaysSupply) {
      setErrorMessage('Please enter days supply');
      return;
    }
    if ((selectedConfigCode == '340BPD' || selectedConfigCode == 'WACPD' || selectedConfigCode == 'AWPPD' || selectedConfigCode == 'NADACBPD' || selectedConfigCode == 'NADACGPD' ||selectedConfigCode == 'VAWPPD' ) && (!selectedDiscountMarkup)) {
      setErrorMessage('Please enter discount/markup');
      return;
    }
    if ((selectedConfigCode == 'DF' || selectedConfigCode == 'NADACBDF' || selectedConfigCode == 'NADACGDF' || selectedConfigCode == 'CDF') && (!selectedFlatAmount && !selectedPercentage)) {
      setErrorMessage('Please enter flat/percenatge');
      return;
    }
    var val = {};
    val.configCode = selectedConfigCode;
    val.configName = configCodes.find(c => c.id === selectedConfigCode)?.value;
    val.drugType = selectedDrugType;
    val.drugTypeName = drugTypes.find(d => d.id == selectedDrugType)?.value;
    val.controlledSubstanceType = selectedControlledSubstance;
    val.controlledSubstanceName = controlledSubstance.find(c => c.id == selectedControlledSubstance)?.value;
    val.daysSupply = selectedAmountDaysSupply;
    val.specialtyDrug = selectedAmountSpecialtyDrug;
    val.specialtyDrugName = specialtyDrug.find(s => s.id == selectedAmountSpecialtyDrug)?.value
    val.flatAmount = selectedFlatAmount;
    if (selectedPercentage)
      val.percentage = selectedPercentage;
    if (selectedDiscountMarkup)
      val.percentage = selectedDiscountMarkup;

    val.id = 'id' + Math.random().toString(16).slice(2);
    setState({
      rows: [...state.rows, { ...val }]
    });
    handleAmountsReset();
  };
  const onHandleDelete = (id) => {
    const updatedRows = rows.filter((item) => item.id !== id);
    setState({
      rows: updatedRows
    });
  };

  const changeConfigCode = (configCode) => {
    setSelectedConfigCode(configCode);
    if (configCode == '340BPD' || configCode == 'WACPD' || configCode == 'AWPPD' || configCode == 'NADACBPD' || configCode == 'NADACGPD' || configCode =='VAWPPD')
      setConfigPriceSelected(true);
    else
      setConfigPriceSelected(false);

  }

  const initialValues = {};

  const fetchViewClaimsData = async (pageDetails = {}, hasColumnFilter) => {
    let filter = { ...pageDetails };
    if (hasColumnFilter) {
      filter.filters = [...initialFilters.filters, ...pageDetails.filters];
      //initialFilters.filters = [...filter.filters];
    } else {
      initialFilters = { ...initialFilters, ...filter };
    }
    filter.claimSourceDetailsId = rowClaimSourceData.claimSourceDetailsId;
    try {
      const { data } = await API.post(
        `/ClaimAnalyzer/getRepriceClaimsById`,
        filter
      );
      if (data) {
        setState({
          data: data.items,
          records: data.totalRecords,
        });
      } else {
        setState({
          data: [],
          records: 0,
        });
      }
    } catch (error) {
      setState({ data: [], records: 0 });
    }
  };

  const handleExport = () => {

    const { access_token } =
      JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    const action = `${process.env.REACT_APP_ENDPOINT}/${process.env.REACT_APP_ENDPOINT_POSTFIX}/ClaimAnalyzer/getRepriceClaimsByIdExport/${rowClaimSourceData?.claimSourceDetailsId}?access_token=${access_token}`;
    onExport(action, initialFilters);
  };

  const onPageChange = (filter, hasFilter) => {
    if (hasFilter) {
      fetchViewClaimsData(filter, true);
    } else {
      fetchViewClaimsData(filter);
    }
  };

  const handleSearchColumnFilters = async (columnFilters) => {
    await fetchViewClaimsData({ ...initialFilters, ...columnFilters }, true);
  };

  const refetch = (hasReset) => {
    if (hasReset) {
      initialFilters = {
        ...initialFilters,
        currentPage: 1,
      };
    }
    fetchViewClaimsData(initialFilters);
  };
  return (
    <>
      <Formik
        innerRef={formikRef}
        onSubmit={handleSubmit}
        initialValues={initialValue}
        validationSchema={validationSchema}
        children={(props) => {
          const { isSubmitting, handleSubmit, setFieldValue } =
            props;
          return (
            <div>
              <Row sm={5} >
                <Col md={2} sm={3} xs={6}>
                  {''}
                  <FormGroup>
                    <Label for="reprice">Repricing Method</Label>
                    <Field
                      name='repriceMethod'
                      type='text'
                      component={FormikSelectField}
                      options={repriceTypes}
                      defaultoption='--Select--'
                      value={selectedRepriceType}
                      onChange={(value) =>
                        handleRepriceMethodChange(value, setFieldValue)
                      }
                      className='required'
                      keys={{
                        id: 'id',
                        label: 'value',
                      }
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={3} sm={3} xs={6} style={{zIndex:99}} hidden={selectedRepriceType != 'PHN'}>
                  <FormGroup>
                    <Label for="pharmacyId">Pharmacy Network ID</Label>
                    <ReactSelect
                      name='pharmacyID'
                      id='pharmacyID'
                      options={pharmaciesData}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      optionSelected={true}
                      isMulti
                      components={{
                        Option, ValueContainer: LimitedChipsContainer
                    }}
                     // component={FormikSelectField}
                     // options={pharmacyNetworks}
                      defaultoption='--Select--'
                      onChange={(value, cp) =>
                        handlePharmacyNetworkChange(value, setFieldValue, cp)}
                    value={selectedPharmacyNetworkID.optionPhSelected}
                    className='claim_info '
                                                      maxMenuHeight={250}
                                                      styles={{
                                                        menu: (provided) => ({
                                                          ...provided,
                                                          zIndex: 99,
                                                        }),
                                                      }}
                     // value={selectedPharmacyNetworkID}
                     // onChange={(value) =>
                       // handlePharmacyNetworkChange(value, setFieldValue)
                     // }
                      
                      keys={{
                        id: 'pbmpharmacyNetworkId',
                        label: 'pharmacyNetworkData',
                      }
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={3} >
                  <Col md="auto" className="pt-4 mt-1">
                    <Label>{""}</Label>
                    <Button hidden={selectedRepriceType == 'CUST'}
                      disabled={rowClaimSourceData?.statusId == '2003' || !selectedNetworkIDS}
                      onClick={handleRepriceSubmit}
                      color="primary"
                      className="ms-3"
                      title='Reprice'
                    >
                      Reprice
                    </Button>
                    <Button color='primary' title='Reset' onClick={handleReset}>
                      Reset
                    </Button>
                  </Col>
                </Col>
              </Row>
            </div>
          );
        }}
      ></Formik>
      {selectedRepriceType == 'CUST' ? (
        <div className='bin_info'>
          <Tabs
            id="controlled-tab-example"
            activeKey={selectedTab}
            onSelect={(k) => setSelectedTab(k)}
          >
            <Tab eventKey="CNFG" title="Configurations">
              <div className='ms-5'>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>Days Supply Limit(Days)</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='dsl'
                      name='dayssupply'
                      type='text'
                      autoComplete='off'
                      onChange={e => setSelectedDaysSupply(e.target.value)}
                      value={selectedDaysSupply}
                    />
                  </Col>
                </Row>
                {/* <Row className='mt-3'>
                  <Col md={2}>
                    <Label>340B Price Basis</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='p340B'
                      name='p340B'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelected340bPrice(e.target.value)}
                      value={selected340bPrice}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : No)</Label>
                  </Col>
                </Row> */}
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>WAC Price Basis</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='wacp'
                      name='wacp'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedWACPrice(e.target.value)}
                      value={selectedWACPrice}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : No)</Label>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>AWP Price Basis</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='awp'
                      name='awp'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedAWPPrice(e.target.value)}
                      value={selectedAWPPrice}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : No)</Label>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>FUL Price Basis</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='fulp'
                      name='fulp'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedFULPrice(e.target.value)}
                      value={selectedFULPrice}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : No)</Label>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>NADACB Price Basis</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='nadacbp'
                      name='nadacbp'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedNADACBPrice(e.target.value)}
                      value={selectedNADACBPrice}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : No)</Label>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>NADACG Price Basis</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='nadacgp'
                      name='nadacgp'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedNADACGPrice(e.target.value)}
                      value={selectedNADACGPrice}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : No)</Label>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>MAC Price Basis</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='macp'
                      name='macp'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedMACPrice(e.target.value)}
                      value={selectedMACPrice}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : No)</Label>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>Usual & Customary Price Basis</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='usctp'
                      name='usctp'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedUCPrice(e.target.value)}
                      value={selectedUCPrice}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : Yes)</Label>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label> Vaccine AWP Price Basis</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='awp'
                      name='awp'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedVAWPPrice(e.target.value)}
                      value={selectedVAWPPrice}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : No)</Label>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>Incentive Fee ($)</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='icf'
                      name='icf'
                      type='text'
                      autoComplete='off'
                      onChange={e => setSelectedIncentiveFee(e.target.value)}
                      value={selectedIncentiveFee}
                      className='required'
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>Allow Specialty at Retail</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='asr'
                      name='asr'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedAllowRetailSpecialty(e.target.value)}
                      value={selectedAllowRetailSpecialty}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : Yes)</Label>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>Allow Compound Drug</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='acdp'
                      name='acdp'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedAllowCompound(e.target.value)}
                      value={selectedAllowCompound}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : Yes)</Label>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>Allow COB</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='acobp'
                      name='acobp'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedAllowCOB(e.target.value)}
                      value={selectedAllowCOB}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : Yes)</Label>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={2}>
                    <Label>Allow Vaccine Drug</Label>
                  </Col>
                  <Col md={2}>
                    <Input
                      id='avd'
                      name='avd'
                      type='select'
                      autoComplete='off'
                      onChange={e => setSelectedAllowVaccineDrug(e.target.value)}
                      value={selectedAllowVaccineDrug}
                    >
                      <option value={''}>-Select-</option>
                      <option value={'Y'}>Yes</option>
                      <option value={'N'}>No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>(Default : Yes)</Label>
                  </Col>
                </Row>
              </div>
            </Tab>
            <Tab eventKey="AMNT" title="Amounts">
              <Card className='border-0'>
                <CardBody>
                  <div className="table-card rounded-table" style={{ overflowX: 'auto' }} >
                    <table className="theme-table table-responsive border-0" >
                      <thead>
                        <tr>
                          <th>Configurations</th>
                          <th>Drug Type</th>
                          <th>Control Substance</th>
                          <th>Days Supply</th>
                          <th>Specialty Drug</th>
                          <th hidden={isConfigPriceSelected}>Fixed Cost -Flat Amount ($)</th>
                          <th hidden={isConfigPriceSelected}>Fixed Cost-Percentage</th>
                          <th hidden={!isConfigPriceSelected}>Discount/Markup($)</th>
                          <th>Options</th>
                        </tr>

                      </thead>
                      <tbody>
                        <Formik
                          onSubmit={handleAmountSubmit}
                          initialValues={initialValues}
                          enableReinitialize={true}
                          children={(props) => {
                            const { isSubmitting, handleSubmit, setSubmitting, setFieldValue } = props;
                            return (
                              <tr>
                                <td style={{ width: 200 }} >
                                  <Input
                                    id='configCode'
                                    name='configCode'
                                    type='select'
                                    autoComplete='off'
                                    className='required'
                                    onChange={e => changeConfigCode(e.target.value)}
                                    value={selectedConfigCode}
                                  >
                                    <option value={''}>--Select--</option>
                                    {configCodes.map((status) => {
                                      const { id, value } = status;
                                      return (
                                        <option key={id} value={id}>
                                          {value}
                                        </option>
                                      );
                                    })}

                                  </Input>
                                </td>
                                <td style={{ width: 200 }}>
                                  <Input disabled={selectedConfigCode == 'NADACBDF' || selectedConfigCode == 'NADACGDF'}
                                    id='drugType'
                                    name='drugType'
                                    type='select'
                                    autoComplete='off'
                                    onChange={e => setSelectedDrugType(e.target.value)}
                                    value={selectedDrugType}
                                  >
                                    <option value={''}>-Select-</option>
                                    {drugTypes.map((status) => {
                                      const { id, value } = status;
                                      return (
                                        <option key={id} value={id}>
                                          {value}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                </td>
                                <td style={{ width: 200 }}>
                                  <Input disabled={true}
                                    id='controlledSubstance'
                                    name='controlledSubstance'
                                    type='select'
                                    autoComplete='off'
                                    onChange={e => setSelectedControlledSubstance(e.target.value)}
                                    value={selectedControlledSubstance}
                                  >
                                    <option value={''}>-Select-</option>
                                    {controlledSubstance.map((status) => {
                                      const { id, value } = status;
                                      return (
                                        <option key={id} value={id}>
                                          {value}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                </td>
                                <td style={{ width: 200 }}>
                                  <Input
                                    id='daysSupply'
                                    name='daysSupply'
                                    type='number'
                                    autoComplete='off'
                                    onChange={e => setSelectedAmountDaysSupply(e.target.value)}
                                    value={selectedAmountDaysSupply}
                                  />
                                </td>
                                <td style={{ width: 200 }}>
                                  <Input disabled={selectedConfigCode == 'CDF'}
                                    id='specialtyDrug'
                                    name='specialtyDrug'
                                    type='select'
                                    autoComplete='off'
                                    onChange={e => setselectedAmountSpecialtyDrug(e.target.value)}
                                    value={selectedAmountSpecialtyDrug}
                                  >
                                    {/* <option value={''}>-Select-</option> */}
                                    {specialtyDrug.map((status) => {
                                      const { id, value } = status;
                                      return (
                                        <option key={id} value={id}>
                                          {value}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                </td>
                                <td style={{ width: 200 }} hidden={isConfigPriceSelected}>
                                  <Input disabled={selectedPercentage}
                                    id='flatAmont'
                                    name='flatAmont'
                                    type='number'
                                    autoComplete='off'
                                    onChange={e => setSelectedFlatAmount(e.target.value)}
                                    value={selectedFlatAmount}
                                  />
                                </td>
                                <td style={{ width: 200 }} hidden={isConfigPriceSelected}>
                                  <Input disabled={selectedFlatAmount}
                                    id='percantage'
                                    name='percentage'
                                    type='number'
                                    autoComplete='off'
                                    onChange={e => setSelectedPercentage(e.target.value)}
                                    value={selectedPercentage}
                                  />
                                </td>
                                <td style={{ width: 200 }} hidden={!isConfigPriceSelected}>
                                  <Input
                                    id='discountMarkup'
                                    name='discountMarkup'
                                    type='number'
                                    autoComplete='off'
                                    onChange={e => setSelectedDiscountMarkup(e.target.value)}
                                    value={selectedDiscountMarkup}
                                  />
                                </td>
                                <td width={100}>
                                  <Button className='width_0' style={{ marginLeft: '-7px' }}
                                    disabled={!selectedConfigCode}
                                    onClick={handleAmountSubmit}
                                    type="submit"
                                    color="link"
                                    title='Add'
                                  >
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      className="text-primary f-22"
                                    />
                                  </Button>
                                  <Button className='width_0'
                                    disabled={isSubmitting}
                                    onClick={handleAmountsReset}
                                    type="submit"
                                    color="link"
                                    title='Cancel'
                                  >
                                    <FontAwesomeIcon
                                      icon={faClose}
                                      className="text-primary f-22"
                                    />
                                  </Button>
                                </td>
                              </tr>
                            );
                          }}
                        />
                        {rows.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.configName}</td>
                              <td>{item.drugTypeName != '' ? item.drugTypeName : '-'}</td>
                              <td>{item.controlledSubstanceName != '' || item.controlledSubstanceName != undefined ? item.controlledSubstanceName : '-'}</td>
                              <td>{item.daysSupply}</td>
                              <td>{item.specialtyDrugName != '' ? item.specialtyDrugName : '-'}</td>
                              <td hidden={isConfigPriceSelected}>{item.flatAmount != '' ? item.flatAmount : '-'}</td>
                              <td hidden={isConfigPriceSelected}>{item.percentage != '' ? item.percentage : '-'}</td>
                              <td hidden={!isConfigPriceSelected}>{item.percentage != '' ? item.percentage : '-'}</td>
                              <td>
                                <Row>
                                  <Col md={3}>
                                    <Button style={{ marginLeft: '-7px' }}
                                      color="link"
                                      title='Delete'
                                      onClick={() => onHandleDelete(item.id)}
                                    >
                                      <HoverImage
                                        key='delIcon'
                                        src={delIcon}
                                        hoverSrc={delhoverIcon}
                                        alt=''
                                      />
                                    </Button>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Tab>
          </Tabs>
          <div className='text-center'>
            <Label className="text-danger font-size-13">{errorMessage}</Label>
          </div>
        </div>) : null}
      {selectedRepriceType == 'CUST' ? (<div>
        <Row>
          <Col md={10}></Col>
          <Col md={2}>
            <Button
              disabled={rowClaimSourceData?.statusId == '2003' || !selectedIncentiveFee || (selectedUCPrice == 'N' && rows.length == 0)}
              onClick={handleRepriceSubmit}
              color="primary"
              className="ms-3"
              title='Save & Reprice'
            >
              Save & Reprice
            </Button>
          </Col>
        </Row>
      </div>) : null}

      <Card className='border-0 rounded-bottom-1 table-card'>
        <CardBody>
          <ViewClaimsTable
            data={state.data}
            //loading={state.loading}
            onExport={handleExport}
            filters={initialFilters}
            totalSize={state.records}
            onPageChange={onPageChange}
            setLoading={(value) => setState({ loading: value })}
            refetch={refetch}
            onSearchColumnFilters={handleSearchColumnFilters}
            reset={state.reset}
          />
        </CardBody>
      </Card></>
  );
};

export default ViewClaims;
