import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './style/pfapp.scss';

import Login from 'pages/Login';
import NotValidTab from 'pages/NotValidTab';
import FileSource from './pages/FileSource'
import { PrivateRoute } from 'routes/privateRoute';
import axios from 'axios';
import { ModuleAccess } from 'utils/moduleaccess';

const App = () => {
  const [modules, setModules] = useState([])

  const fetchModules = async () => {
    const { token_type, access_token, profile } =
      JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    const { sub } = profile || {};
    try {
      if (access_token) {
        // renderFileSource();
      }

      // const { data } = await axios.get(
      //   `${process.env.REACT_APP_ENDPOINT}/RCMConfig/app/modules`,
      //   {
      //     //params: { Id: sub },
      //     headers: {
      //       'Content-type': 'application/json',
      //       Authorization: `${token_type} ${access_token}`,
      //     },
      //   }
      // );
      // if (data) {
      //   setModules(data);
      // }

    } catch (error) {
      console.log(error.message);
    }
  };

  const ThemeSelector = async () => {
    const { token_type, access_token, profile } =
      JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    const { sub } = profile || {};
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/PBMConfig/app/userapptheme`,
        {
          //params: { Id: sub },
          headers: {
            'Content-type': 'application/json',
            Authorization: `${token_type} ${access_token}`,
          },
        }
      );
      if (data) {
        sessionStorage.setItem('theme', data);
        if (data === "PF") {
          import('./style/pfapp.scss');
        }
        else if (data === "CPS") {
          import('./style/app.scss');
        }

      }
    } catch (error) {
      console.log(error.message);
    }



    return (
      <>
      </>
    )
  }

  useEffect(() => {
    //ThemeSelector();
    fetchModules();
  }, [])


  const renderFileSource = () => {
    //let module = modules.filter(m => m.code == "CLM")
    //if (module.length > 0)
    //if (ModuleAccess("CLM", ''))
    return (<Route path='/file-source' element={<FileSource module={module[0]} />} />)
  }

  const renderLogin = () => {
    return (<Route index element={<Login />} />)
    if (false)
      return (
        <>
          <Route index element={<Login />} />
          <Route index path='/login' element={<Login />} />
        </>
      )
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Outlet />}>
            <Route element={<PrivateRoute modules={modules} />}>
              {renderLogin()}
              {renderFileSource()}
              {/* {renderTaskBoard()}
              {renderProviders()}
              {renderFax()}
              {renderPatientClaims()}
              {renderLocation()}
              {renderConfiguration()}
              {renderINV()} */}
            </Route>

            {/* catch all */}
            <Route path='*' element={<NotValidTab />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer position='top-right' autoClose={3000} />
    </>
  );
};

export default App;
