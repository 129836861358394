import React, { useState, useEffect } from "react";
import { Button, Input } from "reactstrap";
import rightIcon from "../../assets/icon/pagination-right.svg";
import leftIcon from "../../assets/icon/pagination-left.svg";
import endIcon from "../../assets/icon/pagination-end.svg";
import firstIcon from "../../assets/icon/pagination-first.svg";

const Pagination = ({ currentPage, totalRecords, onPageChange, pageSize, hasNoEligibility = true, researchNeededCount, eligibleCount, inEligibleCount }) => {
  const [state, setState] = useState({
    value: "",
    error: ''
  });

  useEffect(() => {
    setState(pre => ({ ...pre, value: currentPage }))
  }, [currentPage])

  const getTotalPage = (total, pageSize) => {
    const totalPage = total / pageSize;
    const page = Math.floor(total / pageSize);
    const splitted = totalPage.toString().split('.');
    if (splitted[1]) {
      return page + 1
    } else {
      return page;
    }
  };



  const onPageNext = (value) => {
    switch (value) {
      case "NEXT":
        const page = currentPage + 1;
        if (page <= getTotalPage(totalRecords, pageSize)) {
          setState(pre => ({ ...pre, value: page }))
          return onPageChange(page);
        }
        break;
      case "PREVIOUS":
        const previousPage = currentPage - 1;
        if (previousPage >= 1) {
          setState(pre => ({ ...pre, value: previousPage }))
          return onPageChange(previousPage);
        }
        break;
      default:
        break;
    }
  };

  const onInputChange = (e) => {
    const { value } = e.target;
    if (parseInt(value) === 0) return setState(pre => ({ ...pre, value, error: 'Enter proper page number' }));
    if (value === '') return setState(pre => ({ ...pre, value: '', error: 'Enter proper page number' }));
    if (value > getTotalPage(totalRecords, pageSize)) return setState(pre => ({ ...pre, value, error: 'Enter proper page number' }));;
    setState((pre) => ({ ...pre, value, error: '' }));
  };

  const onKeyEnterPage = e => {
    const { key } = e;
    if (key === '-' || key === '+' || key === '.') e.preventDefault()
    else {
      const { value, error } = state;
      if (key === 'Enter' && !error) {
        onPageChange(parseInt(value))
      }
    }
  }

  return (
    <div className="d-flex justify-content-end align-items-center rcm-pagination">
      <div className="me-4">
        <span className="text-danger">{state.error}</span>
      </div>

      <div hidden={hasNoEligibility}>
        <span>Research Required : </span> {researchNeededCount} &nbsp;&nbsp;
        <span>Eligible : </span> {eligibleCount} &nbsp;&nbsp;
        <span>Ineligible : </span> {inEligibleCount} &nbsp;&nbsp;
      </div>

      <Button
        disabled={currentPage === 1 || totalRecords === 0}
        onClick={() => onPageChange(1)}
        color="link"
        className="px-0"
      >
        <img src={firstIcon} alt="firstIcon" />
      </Button>
      <Button
        disabled={currentPage === 1 || totalRecords === 0}
        onClick={() => onPageNext("PREVIOUS")}
        color="link"
        className="px-0"
      >
        <img src={leftIcon} alt="leftIcon" />
      </Button>
      <div>
        <Input
          type="number"
          value={state.value}
          onChange={onInputChange}
          className="mx-2"
          onKeyDown={onKeyEnterPage}
          disabled={totalRecords === 0}
        />
      </div>
      <div className="font-size-16 pe-2">
        <span>/ {getTotalPage(totalRecords, pageSize)}</span>
        <span>({totalRecords})</span>
      </div>
      <Button
        color="link"
        disabled={currentPage === getTotalPage(totalRecords, pageSize) || totalRecords === 0}
        onClick={() => onPageNext("NEXT")}
        className="px-0"
      >
        <img src={rightIcon} height="18" />
      </Button>
      <Button
        disabled={currentPage === getTotalPage(totalRecords, pageSize) || totalRecords === 0}
        onClick={() => onPageChange(getTotalPage(totalRecords, pageSize))}
        color="link"
        className="px-0"
      >
        <img src={endIcon} />
      </Button>
    </div>
  );
};

export default Pagination;
