import { cloneDeep } from 'lodash';

const defaultFileSourceFilter = {
  currentPage: 1,
  pageSize: 10,
  order: 'desc',
  orderBy: '',
  filters: [],
};

const defaultStates = {
  fileSource: {
    hasFilterd: false,
    search: cloneDeep(defaultFileSourceFilter),
    fileSourceData: [],
    searchTerms: {
      selectedFileSource: '',
      repriceType: '',
    },
  },
};

const initialStates = {
  loading: false,
  fileSource: cloneDeep(defaultStates.fileSource),
};

export { initialStates, defaultStates };
