import React, { useState, useEffect } from "react";
import { includes } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, NavItem } from "reactstrap";
import ClaimIcon from "assets/icon/claim-icon.svg";
import FaxIcon from "assets/icon/fax.svg";
import Providers from "assets/icon/providers.svg";
import Location from "assets/icon/location.svg";
import ConfigIcon from "assets/icon/config-icon.svg";
import Invoice from "assets/icon/inventory.svg";
import DashboardIcon from "assets/icon/dashboard.svg";
import useAxios from 'hooks/useAxios';
import { Alert } from "bootstrap";
import { ModuleAccess } from "utils/moduleaccess";
const Subheader = (props) => {
  const { modules } = props;
  const { pathname } = useLocation();

  const hasModule = (code) => {
    //return modules.length > 0 && modules.filter(m => m.code == code).length > 0;
    return ModuleAccess(code);
  }

  const renderFileSource = () => {
    //if (hasModule('CLM'))
    return (
      <NavItem>
        <Link
          to="/file-source"
          className={`nav-link ${includes(pathname, "/file-source") ? "active" : ""
            }`}
        >
          <img src={ClaimIcon} alt="" className="me-2" />
          <span>File Source</span>
        </Link>
      </NavItem>
    )

  }
  const renderFax = () => {
    if (hasModule('FAX'))
      return (
        <NavItem>
          <Link
            to="/fax"
            className={`nav-link ${includes(pathname, "/fax") ? "active" : ""}`}
          >
            <img src={FaxIcon} alt="" className="me-2" />
            <span>Fax</span>
          </Link>
        </NavItem>
      )
  }
  const renderProviders = () => {
    if (hasModule('PRD'))
      return (
        <NavItem>
          <Link
            to="/providers"
            className={`nav-link ${includes(pathname, "/providers") ? "active" : ""
              }`}
          >
            <img src={Providers} alt="" className="me-2" />
            <span>Providers</span>
          </Link>
        </NavItem>
      )
  }
  const renderLocations = () => {
    if (hasModule('SL'))
      return (
        <NavItem>
          <Link
            to="/location"
            className={`nav-link ${includes(pathname, "/location") ? "active" : ""
              }`}
          >
            <img src={Location} alt="" className="me-2" />
            <span>Location</span>
          </Link>
        </NavItem>)
  }
  const renderINV = () => {
    if (hasModule('INV'))
      return (
        <NavItem>
          <Link
            to="/invoice"
            className={`nav-link ${includes(pathname, "/invoice") ? "active" : ""
              }`}
          >
            <img src={Invoice} alt="" className="me-2" />
            <span>Invoice</span>
          </Link>
        </NavItem>)
  }

  const renderConfiguration = () => {
    if (hasModule('CON'))
      return (
        <NavItem>
          <Link
            to="/configuration"
            className={`nav-link ${includes(pathname, "/configuration") ? "active" : ""
              }`}
          >
            <img src={ConfigIcon} alt="" className="me-2" />
            <span>Configurations</span>
          </Link>
        </NavItem>)
  }

  const renderTaskBoard = () => {
    if (hasModule('TB'))
      return (
        <NavItem>
          <Link
            to="/taskboard"
            className={`nav-link ${includes(pathname, "/taskboard") ? "active" : ""
              }`}
          >
            <img src={Invoice} alt="" className="me-2" />
            <span>TaskBoard</span>
          </Link>
        </NavItem>)

  }

  return (
    <Navbar color="dark" dark className="app-subheader">
      <Nav>
        {/* {renderTaskBoard()} */}
        {renderFileSource()}
        {/* {renderFax()}
        {renderProviders()}
        {renderLocations()}
        {renderConfiguration()}
        {renderINV()} */}
      </Nav>
    </Navbar>
  );
};

export default Subheader;
