import React from 'react';
import moment from 'moment';

export const cellRendererCheck = (value) => {
  if (!value) return '-';
  return value;
};


export const cellRendererToolTip = (row) => {
  if (!row.faxStatus) return '-';
  return (<span title={row.faxStatusToolTip}>{row.faxStatus}</span>);
}

export const currencyFormat = (value) => {
  if (!value || isNaN(value)) return '-';
  if (value < 0)
    return (
      <span className='text-danger text-end'>
        {'-$' +
          (value * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
      </span>
    );
  else
    return (
      <span className='text-end'>
        {'$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
      </span>
    );
};

export const fileFormatter = (row, download) => {
  const { files } = row;
  if (files && files.length) {
    return files.map((file, index) => {
      return (
        <span
          className='fax-link'
          key={`${file.fileName}${index}`}
          onClick={() => download(file)}
        >
          {file.fileName}
          <br />
        </span>
      );
    });
  }
  return <span>There is no attachment</span>;
};

export const dateFormate = (value) => {
  if (!value) return '-';
  return moment(new Date(value)).format('MM/DD/YYYY');
};

export const fileSourceColumns = [
  {
    dataField: 'sourceDescription',
    text: 'Source',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.sourceDescription),
  },
  {
    dataField: 'name',
    text: 'File Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.name),
  },
  {
    dataField: 'receivedDate',
    text: 'Date Loaded',
    sort: true,
    width: 140,
    show: true,
    inputType: 'date',
    hideColFilter: false,
    cellRenderer: (row) => dateFormate(row.receivedDate),
  },
  {
    dataField: 'statusDescription',
    text: 'Status',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.statusDescription),
  },
  {
    dataField: 'totalRecordCount',
    text: 'Total Records',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.totalRecordCount),
  },
  {
    dataField: 'processedRecordCount',
    text: 'Records Passed',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.processedRecordCount),
  },
  {
    dataField: 'failedRecordCount',
    text: 'Records Failed',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.failedRecordCount),
  },
  {
    dataField: 'sourcePlanPayAmt',
    text: 'Source Plan Pay',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.sourcePlanPayAmt),
  },
  {
    dataField: 'repricedPlanPayAmt',
    text: 'PF Plan Pay',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.repricedPlanPayAmt),
  },
  {
    dataField: 'repriceDescription',
    text: 'Repriced Method',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.repriceDescription),
  },
  {
    dataField: 'hygeiaNetworkId',
    text: 'Pharmacy NetworkID',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.hygeiaNetworkId),
  },
  {
    dataField: 'repricedCount',
    text: 'Repriced Count',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.repricedCount),
  },
  {
    dataField: 'nonRepricedCount',
    text: 'Non-Repriced Count',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.nonRepricedCount),
  },
  {
    dataField: 'reasonDesc',
    text: 'Reason Description',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.reasonDesc),
  }
]
export const viewClaimsColumns = [
  {
    dataField: 'sourceClaimID',
    text: 'Source ClaimID',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.sourceClaimID),
  },
  {
    dataField: 'planCode',
    text: 'Plan Code',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.planCode),
  },
  {
    dataField: 'groupCode',
    text: 'Group Code',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.groupCode),
  },
  {
    dataField: 'pharmacyNPI',
    text: 'Pharmacy NPI',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.pharmacyNPI),
  },
  {
    dataField: 'pharmacyNCPDP',
    text: 'Pharmacy NCPDP',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.pharmacyNCPDP),
  },
  {
    dataField: 'dateOfService',
    text: 'Date Of Service',
    sort: true,
    width: 140,
    show: true,
    inputType: 'date',
    hideColFilter: false,
    cellRenderer: (row) => dateFormate(row.dateOfService),
  },
  {
    dataField: 'rxNumber',
    text: 'Rx Number',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.rxNumber),
  },
  {
    dataField: 'fillNumber',
    text: 'Fill Number',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.fillNumber),
  },
  {
    dataField: 'pharmacyServiceType',
    text: 'ServiceType',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.pharmacyServiceType),
  },
  {
    dataField: 'ndc',
    text: 'NDC',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.ndc),
  },
  {
    dataField: 'quantityDispensed',
    text: 'Quantity Dispensed',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.quantityDispensed),
  },
  {
    dataField: 'unitOfMeasure',
    text: 'Unit Of Measure',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.unitOfMeasure),
  },
  {
    dataField: 'daysSupply',
    text: 'Days Supply',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.daysSupply),
  },
  {
    dataField: 'basisOfReimbursement',
    text: 'Basis Of Reimbursement',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.basisOfReimbursement),
  },
  {
    dataField: 'submitted_UC',
    text: 'Submitted UC',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.submitted_UC),
  },
  {
    dataField: 'ingredientCostPaid',
    text: 'Ingredient Cost Paid',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.ingredientCostPaid),
  },
  {
    dataField: 'dispensingFee',
    text: 'Dispensing Fee Approved',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.dispensingFee),
  },
  {
    dataField: 'incentiveAmt',
    text: 'Incentive Amount Approved',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.incentiveAmt),
  },
  {
    dataField: 'planPayAmt',
    text: 'Plan Pay Amount',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.planPayAmt),
  },
  {
    dataField: 'patientPayAmt',
    text: 'Patient Pay Amount',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.patientPayAmt),
  },
  {
    dataField: 'totalPrice',
    text: 'Total Price',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.totalPrice),
  },
  {
    dataField: 'authID',
    text: 'Auth ID',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.authID),
  },
  {
    dataField: 'isRepriced',
    text: 'PF Reprice Indicator',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.isRepriced),
  },
  {
    dataField: 'pfBasisOfReimbursement',
    text: 'PF Basis Of Reimbursement',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.pfBasisOfReimbursement),
  },
  {
    dataField: 'pfawPrice',
    text: 'PF Rx AWP',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfawPrice),
  },
  {
    dataField: 'pfwacPrice',
    text: 'PF Rx WAC',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfwacPrice),
  },
  {
    dataField: 'pfnadacbPrice',
    text: 'PF Rx NADACB',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfnadacbPrice),
  },
  {
    dataField: 'pfnadacgPrice',
    text: 'PF Rx NADACG',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfnadacgPrice),
  },
  {
    dataField: 'pfmacPrice',
    text: 'PF Rx MAC',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfmacPrice),
  },
  {
    dataField: 'pffulPrice',
    text: 'PF Rx FUL',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pffulPrice),
  },
  {
    dataField: 'pfsubmitted_UC',
    text: 'PF Rx U&C',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfsubmitted_UC),
  },
  {
    dataField: 'pfIngredientCostPaid',
    text: 'PF Ingredient Cost Approved',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfIngredientCostPaid),
  },
  {
    dataField: 'pfDispensingFee',
    text: 'PF Dispensing Fee Approved',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfDispensingFee),
  },
  {
    dataField: 'pfnadacbDispensingFee',
    text: 'PF NADACB Dispensing Fee Approved',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfnadacbDispensingFee),
  },
  {
    dataField: 'pfnadacgDispensingFee',
    text: 'PF NADACG Dispensing Fee Approved',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfnadacgDispensingFee),
  },
  {
    dataField: 'pfIncentiveAmt',
    text: 'PF Incentive Fee Approved',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfIncentiveAmt),
  },
  {
    dataField: 'pfPlanPayAmt',
    text: 'PF Plan Pay Amount',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfPlanPayAmt),
  },
  {
    dataField: 'pfTotalPrice',
    text: 'PF Total Price',
    sort: true,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: false,
    cellRenderer: (row) => currencyFormat(row.pfTotalPrice),
  },
  {
    dataField: 'reasonDesc',
    text: 'Reason Description',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: false,
    cellRenderer: (row) => cellRendererCheck(row.reasonDesc),
  }
]