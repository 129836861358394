import React, { useEffect, useState } from 'react';
import { cellRendererCheck, currencyFormat, dateFormate } from 'utils/columns';
import ReactStrapTable from '../../../components/reactStrapTable'
import { viewClaimsColumns } from '../../../utils/columns';

const columns = [
    {
        dataField: 'options',
        text: 'Options',
        sort: false,
        width: 80,
        show: true,
        hidecolFilter: true,
        action: true
    },
    ...viewClaimsColumns
];

const ViewClaimsTable = ({
    data,
    loading,
    onExport,
    filters,
    totalSize,
    onPageChange,
    refetch,
    setLoading,
    onSearchColumnFilters,
    reset,
}) => {
    const [state, updateState] = useState({
        tableData: [],
        backUpData: [],
        filters: filters,
    });
    useEffect(() => {
        setState({ tableData: data, backUpData: data });
    }, [data]);
    const setState = (obj) => updateState(pre => ({ ...pre, ...obj }));

    useEffect(() => {
        setState({ filters: filters });
    }, [filters]);

    const onPageSizeChange = (filter, hasColFilter, colChangedFilter) => {
        if (hasColFilter) {
            setState({
                filters: filter,
            });
            let colFilterOn = { ...filter, filters: colChangedFilter };
            return onPageChange(colFilterOn, true);
        }
        setState({
            filters: filter,
        });
        onPageChange(filter);
    };

    const handleFilterCancel = () => {
        refetch(true);
    };

    const handleSearchColumnFilters = (colFilter) => {
        if (onSearchColumnFilters) {
            const filter = { ...state.filters, filters: colFilter, currentPage: 1 };
            onSearchColumnFilters(filter);
            setState({
                filters: filter,
            });
        }
    };

    const onHandlePageChange = (filter, hasColFilter, colChangedFilter) => {
        if (hasColFilter) {
            setState({
                filters: filter,
            });
            let colFilterOn = { ...filter, filters: colChangedFilter };
            onPageChange(colFilterOn, true);
        } else {
            setState({
                filters: filter,
            });
            onPageChange(filter);
        }
    };

    const handleSort = (filter, hasColFilter) => {
        setState({
            filters: filter,
        });
        if (hasColFilter) {
            onPageChange(filter, true);
        } else {
            onPageChange(filter);
        }
    };

    const { filters: colFilter, backUpData } = state;


    return <div className="table-card scroll-table position-relative border-0 rounded pt-0"
        fixed-height={data.length > 0 ? 'fixed-height' : 'default'}>
        <ReactStrapTable
            id='viewClaims-table'
            fixedColumn
            data={backUpData}
            loading={loading}
            columns={columns}

            onPageSizeChange={onPageSizeChange}
            pageSize={colFilter.pageSize}
            onExport={onExport}
            hasPagination
            onPageChange={onHandlePageChange}
            currentPage={colFilter.currentPage}
            totalRecords={totalSize}
            handleSort={handleSort}
            onFilterCancel={handleFilterCancel}
            filters={colFilter}
            onSearchColumnFilters={handleSearchColumnFilters}
        />
    </div>;
};

export default ViewClaimsTable;