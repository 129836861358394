import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { isBoolean } from 'lodash';
import HoverImage from 'react-hover-image/build';
import { fileSourceColumns } from 'utils/columns';
import ReactStrapTable from 'components/reactStrapTable';
import EyeIcon from 'assets/icon/icon-icon-options-view-patient.svg';
import EyeIconHover from 'assets/icon/icon-icon-options-view-patient_hover.svg';
import useAxios from 'hooks/useAxios';
import { ModuleAccess } from 'utils/moduleaccess';
import ViewClaims from '../../../components/ViewClaims';

const FileSourceTable = ({
  data,
  totalRecords,
  filters,
  onPageChange,
  onSearchColumnFilters,
  refetch,
  loading,
  // selectedCoveredEntity,
  // tenantID,
  onExport,
}) => {
  const [state, updateState] = useState({
    loading: false,
    tableData: [],
    backUpData: [],
    filters: filters,
  });
  const API = useAxios();
  const [isOpenClaims, setIsOpenClaims] = useState(false);
  const { isOpen, invoice } = state;
  const [selectedRow, setSelectedRow] = useState({});
  const [showDownload, setShowDownload] = useState(false);
  const [hasExportPermission, setHasExportPermission] = useState(false);

  useEffect(() => {
    setState({ tableData: data, backUpData: data });
  }, [data]);

  const setState = (obj) => updateState((pre) => ({ ...pre, ...obj }));

  useEffect(() => {
    setState({ filters: filters });
  }, [filters]);

  useEffect(() => {
    //setHasExportPermission(ModuleAccess("INV", "EXP"));
  }, []);

  const previewRePrice = (claim) => {
    return (
      <div className='d-flex align-items-center'>
        <Button hidden={claim.statusId == '2001' || claim.statusId == '2005'}
          className='text-primary p-0 text-decoration-none'
          color='link'
          title='View claim reprice'
          onClick={() => toggleViewClaims(claim)}
        >
          <HoverImage
            key='EyeIcon'
            src={EyeIcon}
            hoverSrc={EyeIconHover}
            alt=''
          />
        </Button>
      </div>
    );
  };

  const toggleViewClaims = (claim) => {
    setSelectedRow(claim);
    setIsOpenClaims(!isOpenClaims);
  };

  let columns = [
    {
      dataField: 'edit',
      text: 'Options',
      width: 80,
      show: true,
      disabled: true,
      hideColFilter: true,
      action: true,
      cellRenderer: (row) => previewRePrice(row),
    },
    ...fileSourceColumns,
  ];

  const onPageSizeChange = (filter, hasColFilter, colChangedFilter) => {
    if (hasColFilter) {
      setState({
        filters: filter,
      });
      let colFilterOn = { ...filter, filters: colChangedFilter };
      return onPageChange(colFilterOn, true);
    }
    setState({
      filters: filter,
    });
    onPageChange(filter);
  };

  const handleSort = (filter, hasColFilter) => {
    setState({
      filters: filter,
    });
    if (hasColFilter) {
      onPageChange(filter, true);
    } else {
      onPageChange(filter);
    }
  };

  const handleSearchColumnFilters = (colFilter) => {
    if (onSearchColumnFilters) {
      const filter = { ...state.filters, filters: colFilter, currentPage: 1 };
      onSearchColumnFilters(filter);
      setState({
        filters: filter,
      });
    }
  };

  const handleFilterCancel = () => {
    refetch(true);
  };
  const onHandlePageChange = (filter, hasColFilter, colChangedFilter) => {
    if (hasColFilter) {
      setState({
        filters: filter,
      });
      let colFilterOn = { ...filter, filters: colChangedFilter };
      onPageChange(colFilterOn, true);
    } else {
      setState({
        filters: filter,
      });
      onPageChange(filter);
    }
  };
  // const togglePdfModal = (hasRefresh) => {
  //   if (isBoolean(hasRefresh) && hasRefresh) {
  //     refetch();
  //   }
  //   setState({
  //     isOpen: !isOpen,
  //   });
  // };

  const { filters: colFilter, backUpData } = state;

  return (
    <div className='table-card scroll-table position-relative border-0 rounded pt-0'
      fixed-height={data.length > 0 ? 'fixed-height' : 'default'}
    >
      <ReactStrapTable
        id='fileSource-table'
        fixedColumn
        data={backUpData}
        loading={loading}
        hasPagination
        columns={columns}
        pageSize={colFilter.pageSize}
        totalRecords={totalRecords}
        currentPage={colFilter.currentPage}
        handleSort={handleSort}
        onPageChange={onHandlePageChange}
        onPageSizeChange={onPageSizeChange}
        onFilterCancel={handleFilterCancel}
        filters={colFilter}
        onSearchColumnFilters={handleSearchColumnFilters}
        onExport={onExport}
      //hasExportPermission={hasExportPermission}
      />

      <Modal
        isOpen={isOpenClaims}
        toggle={toggleViewClaims}
        size='xl'
        className='xxl'
      >
        <ModalHeader toggle={toggleViewClaims}>
          Claim Reprice
        </ModalHeader>
        <ModalBody>
          <ViewClaims rowClaimSourceData={selectedRow} toggle={toggleViewClaims} claimSourceRefetch={refetch} />
        </ModalBody>
      </Modal>

    </div>
  );
};

export default FileSourceTable;
