import React, { useRef, useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { Button, FormGroup, Label, Row, Col, Modal, ModalHeader, ModalBody, InputGroup, InputGroupText, Input } from "reactstrap";
import { cloneDeep } from 'lodash';
import { default as ReactSelect } from "react-select";
import * as Yup from "yup";
import FormikDatePicker from 'components/common/Fields/FormikDatePicker';
import Calendar from 'assets/icon/calendar-icon.svg';
import moment from 'moment';
import useContextService from 'hooks/useContextService';
import Plan from "../../../components/SearchPlan";
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import useAxios from 'hooks/useAxios';
import { ModuleAccess } from 'utils/moduleaccess';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
  // fileSource: Yup.string().required("Please select file type"),
  // planId: Yup.string().required("Please select Plan")
});

let hasDatePopupOpen = false;

const SearchContainer = ({
  // handleUpdateEntity,
  //handleInvoiceStatus,
  onSearch,
  //onManualSearch,
  onReset,
  initialValue,
  // onCoverdEntityChange,
  // selectedPlanId,
  showTableGrid,
  refetch
}) => {
  const API = useAxios();
  const [isHygeiaChecked, setIsHygeiaChecked] = useState(false);
  const [isOpenHygeia, setIsOpenHygeia] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedPlanCode, setSelectedPlanCode] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedGroupCode, setSelectedGroupCode] = useState('');
  const [selectedFileStartDate, setSelectedFileStartDate] = useState('');
  const [selectedFileEndDate, setSelectedFileEndDate] = useState('');
  const [sdErrorMessage, setSDErrorMessage] = useState('');
  const [edErrorMessage, setEDErrorMessage] = useState('');
  const [state, updateState] = useState({
    selectedPlanId: '',
    loading: false,
    selectedGroup: ''
  });

  const {
    states: { fileSource },
    setStates,
    resetStates,
  } = useContextService();

  const updateStates = (fileSource) => {
    setStates('fileSource', fileSource);
  };

  const { searchTerms } = fileSource;

  let {
    selectedFileSource
  } = searchTerms;
  const formikRef = useRef();
  useEffect(() => {
    setState({ loading: true });
  }, []);

  const setState = (obj) => updateState((pre) => ({ ...pre, ...obj }));

  const toggleHygeia = () => {
    setIsOpenHygeia(!isOpenHygeia);
  };


  const handleSubmit = async (val, actions) => {
    const { setSubmitting } = actions;
    const values = cloneDeep(val);
    await onSearch(values);
    setSubmitting(false);
  };
  const setIsHygeia = () => {
    setIsHygeiaChecked(true);
    showTableGrid(true);
  };
  const handleHygeiaCancel = () => {
    setIsHygeiaChecked(false);
    showTableGrid(false);
    handleReset();
  }

  const handleReset = () => {
    const { resetForm } = formikRef.current;
    resetForm();
    onReset();
    resetStates('fileSource');
    setData([]);
    handleHygeiaReset();
  };
  const handleHygeiaReset = () => {
    setSelectedGroup('');
    setSelectedPlan('');
    setStartDate('');
    setEndDate('');
    setData([]);
    setSelectedFileName('');
    setSelectedGroupCode('');
    setSelectedFileStartDate('');
    setSelectedFileEndDate('');
    setSelectedPlanCode('');
    setSDErrorMessage('');
    setEDErrorMessage('');
  }

  // const {
  //   invoiceStatusDesc,
  //   invoiceStatus,
  //   selectedCoveredEntity,
  // } = state;

  // const handleManual = async () => {
  //   let ceFilters = selectedCoveredEntity;
  //   ceFilters.invoiceID = 0;
  //   await onManualSearch(ceFilters);
  // }
  const handleDateClick = (id) => {
    if (hasDatePopupOpen) {
      hasDatePopupOpen = false;
      return;
    }
    const calendarButton = document.getElementById(id);
    if (calendarButton) {
      calendarButton.click();
      hasDatePopupOpen = true;
    }
  };

  const handleFileSourceChange = async ({ target: { value } }, setFieldValue) => {
    fileSource.searchTerms.selectedFileSource = value;
    updateStates(fileSource);
    setFieldValue("fileSource", value);
  };

  const handleGroupChange = async ({ target: { value } }, setFieldValue) => {
    setSelectedGroup(value);
    setFieldValue("groupId", value);
    let groupCode = groupsData?.find(g => g.pbmGroupID == value)?.groupCode;
    setSelectedGroupCode(groupCode);
    if (groupCode && !selectedFileStartDate && !selectedFileEndDate)
      setSelectedFileName(`${selectedPlanCode}_${groupCode}`);
    else if (groupCode && selectedFileStartDate && !selectedFileEndDate)
      setSelectedFileName(`${selectedPlanCode}_${groupCode}_${selectedFileStartDate}`);
    else if (groupCode && selectedFileStartDate && selectedFileEndDate)
      setSelectedFileName(`${selectedPlanCode}_${groupCode}_${selectedFileStartDate}_${selectedFileEndDate}`);
    else if (!groupCode && selectedFileStartDate && selectedFileEndDate)
      setSelectedFileName(`${selectedPlanCode}_${selectedFileStartDate}_${selectedFileEndDate}`);
    else if (!groupCode && selectedFileStartDate && !selectedFileEndDate)
      setSelectedFileName(`${selectedPlanCode}_${selectedFileStartDate}`);
    else if (groupCode && !selectedFileStartDate && selectedFileEndDate)
      setSelectedFileName(`${selectedPlanCode}_${selectedFileEndDate}`);
    else
      setSelectedFileName(`${selectedPlanCode}`);
  };

  const handlePlanChange = (value, setFieldValue, entity) => {
    setData([]);
    setSelectedGroup('');
    setSelectedPlanCode(entity.planCode);
    window.selectedPlanId = value;
    setSelectedPlan(value);
    setFieldValue("planId", value);
    setState({
      selectedPlan: value,
    });
    if (value)
      fetchGroupsByPlanID(value);

    setSelectedFileName(entity.planCode);
    if (entity.planCode && selectedFileStartDate && selectedFileEndDate)
      setSelectedFileName(`${entity.planCode}_${selectedFileStartDate}_${selectedFileEndDate}`);
    else if (entity.planCode && selectedFileStartDate && !selectedFileEndDate)
      setSelectedFileName(`${entity.planCode}_${selectedFileStartDate}`);
    else if (entity.planCode && !selectedFileStartDate && selectedFileEndDate)
      setSelectedFileName(`${entity.planCode}_${selectedFileEndDate}`);

  };
  const fetchGroupsByPlanID = async (planId) => {
    setLoading(true);
    const { data } = await API.post(`/Group/getGroupsByPlanId/${planId}`, {
      pbmAPI: true,
    });
    if (data) {
      data.map((group) => {
        group.groupDetails = `${group.groupCode}-${group.groupDesc}`;
      })
      setLoading(false);
      setData(data);
    }
  }
  const handleHygeiaAdd = async () => {
    let filter = {};
    filter.planCode = selectedPlanCode;
    filter.groupCode = selectedGroupCode;
    filter.dosStartDate = startDate;
    filter.dosEndDate = endDate;
    filter.name = selectedFileName.trim();

    if (filter) {
      try {
        const data = await API.post(
          `/ClaimAnalyzer/saveHygeiaClaims`,
          filter,
        );
        if (data?.data) {
          refetch();
          handleHygeiaReset();
          setIsHygeiaChecked(false);
          showTableGrid(false);
        } else {
          // setState({

          // });
        }
      } catch (error) {
        //setState({ loading: false, data: [], records: 0 });
      }
    }
  }
  const handleMouseLeave = () => {
    hasDatePopupOpen = false;
  };

  const handleStartDate = (dosStartDate) => {
    if (dosStartDate) {
      setSDErrorMessage('');
      let date = moment(new Date(dosStartDate)).format('MM/DD/YYYY').split('/');
      if (date[2].length != 4) {
        return;
      }
      if (endDate && (new Date(dosStartDate) > new Date(endDate))) {
        setSDErrorMessage('Start date cannot be greater of end date');
        return;
      }
      setStartDate(moment(new Date(dosStartDate)).format(
        'MM/DD/YYYY'
      ));

      let data = moment(new Date(dosStartDate)).format(
        'MM/DD/YYYY').replace('/', '').trim();
      data = `${data.replace('/', '').trim()}`;

      if (dosStartDate && selectedPlan && !selectedGroupCode && !selectedFileEndDate) {
        setSelectedFileStartDate(data);
        setSelectedFileName(`${selectedPlanCode}_${data}`);
      }
      else if (dosStartDate && selectedPlan && selectedGroupCode && !selectedFileEndDate) {
        setSelectedFileStartDate(data);
        setSelectedFileName(`${selectedPlanCode}_${selectedGroupCode}_${data}`);
      }
      else if (dosStartDate && selectedPlan && selectedGroupCode && selectedFileEndDate) {
        setSelectedFileStartDate(data);
        setSelectedFileName(`${selectedPlanCode}_${selectedGroupCode}_${data}_${selectedFileEndDate}`);
      }
      else if (dosStartDate && selectedPlan && !selectedGroupCode && selectedFileEndDate) {
        setSelectedFileStartDate(data);
        setSelectedFileName(`${selectedPlanCode}_${data}_${selectedFileEndDate}`);
      }
    }
  };

  const handleEndDate = (dosEndDate) => {
    if (dosEndDate) {
      setEDErrorMessage('');
      let date = moment(new Date(dosEndDate)).format('MM/DD/YYYY').split('/');
      if (date[2].length != 4) {
        return;
      }
      if (startDate && (new Date(startDate) > new Date(dosEndDate))) {
        setEDErrorMessage('End date cannot be lesser of start date');
        //toast.error('End date cannot be lesser of start date');
        return;
      }
      setEndDate(moment(new Date(dosEndDate)).format(
        'MM/DD/YYYY'
      ));

      let data = moment(new Date(dosEndDate)).format(
        'MM/DD/YYYY').replace('/', '').trim();
      data = `${data.replace('/', '').trim()}`;

      if (dosEndDate && selectedPlan && selectedFileStartDate && !selectedGroupCode) {
        setSelectedFileEndDate(data);
        setSelectedFileName(`${selectedPlanCode}_${selectedFileStartDate}_${data}`);
      }
      else if (dosEndDate && selectedPlan && selectedGroupCode && selectedFileStartDate) {
        setSelectedFileEndDate(data);
        setSelectedFileName(`${selectedPlanCode}_${selectedGroupCode}_${selectedFileStartDate}_${data}`);
      }
    }
  };
  const groupsData = loading ? [] : data || [];

  const fileSourceType = [
    { id: '1002', value: 'External Claims' },
    { id: '1001', value: 'Hygeia Claims' }];

  return (
    <>
      <Formik
        innerRef={formikRef}
        onSubmit={handleSubmit}
        initialValues={initialValue}
        validationSchema={validationSchema}
        children={(props) => {
          const { isSubmitting, handleSubmit, setFieldValue } =
            props;
          return (
            <div>
              <h5 className="f-15 text-primary font-weight-700" hidden={!isHygeiaChecked}>
                Fetch Hygeia Claims
              </h5>
              <Row sm={5} >
                <Col md={2} sm={3} xs={6} hidden={isHygeiaChecked}>
                  {" "}
                  <FormGroup>
                    <Label for="fileSource">File Source</Label>
                    <Field
                      name='fileSource'
                      type='text'
                      component={FormikSelectField}
                      options={fileSourceType}
                      defaultoption='ALL'
                      value={selectedFileSource}
                      onChange={(value) =>
                        handleFileSourceChange(value, setFieldValue)
                      }
                      className='required'
                      keys={{
                        id: 'id',
                        label: 'value',
                      }
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={3} hidden={isHygeiaChecked}>
                  <Col md="auto" className="pt-4 mt-1">
                    <Label>{""}</Label>
                    <Button
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                      color="primary"
                      className="ms-3"
                      title="Search"
                    >
                      Search
                    </Button>
                    <Button color='primary' title="Reset" onClick={() => handleReset(props)}>
                      Reset
                    </Button>
                  </Col>
                </Col>
                <Col md={2} sm={3} xs={6} hidden={!isHygeiaChecked}>
                  {' '}
                  <FormGroup>
                    <Label for='plan'>Plan</Label>
                    <Plan
                      value={selectedPlan}
                      name='planId'
                      onChange={(value, enty) =>
                        handlePlanChange(value, setFieldValue, enty)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={2} sm={3} xs={6} hidden={!isHygeiaChecked}>
                  {' '}
                  <FormGroup>
                    <Label for='group'>Group</Label>
                    <Field
                      name='groupId'
                      type='text'
                      component={FormikSelectField}
                      options={groupsData}
                      value={selectedGroup}
                      onChange={(value) =>
                        handleGroupChange(value, setFieldValue)
                      }
                      defaultoption='--Select--'
                      keys={{
                        id: 'pbmGroupID',
                        label: 'groupDetails',
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={3} sm={6} hidden={!isHygeiaChecked} style={{ width: '26%' }}>
                  <Row>
                    <Col >
                      <FormGroup>
                        <Label>DOS Start Date</Label>
                        <InputGroup onMouseLeave={handleMouseLeave}>
                          <Field
                            type='text'
                            id='startDate'
                            name='startDate'
                            autoComplete='off'
                            className='required'
                            placeholder='Select a date'
                            value={startDate}
                            component={FormikDatePicker}
                            onChange={handleStartDate}
                          />
                          <InputGroupText
                            onClick={() => handleDateClick('startDate')}
                          >
                            <img src={Calendar} alt='' />
                          </InputGroupText>
                        </InputGroup>
                        <Label className="text-danger font-size-12">{sdErrorMessage}</Label>
                      </FormGroup>
                    </Col>
                    <Col xs='auto' className='pt-3 mt-3'>
                      <Label className='mt-1'>-</Label>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>DOS End Date</Label>
                        <InputGroup onMouseLeave={handleMouseLeave}>
                          <Field
                            type='text'
                            id='endDate'
                            name='endDate'
                            className='required'
                            autoComplete='off'
                            placeholder='Select a date'
                            value={endDate}
                            component={FormikDatePicker}
                            onChange={handleEndDate}
                          />
                          <InputGroupText
                            onClick={() => handleDateClick('endDate')}
                          >
                            <img src={Calendar} alt='' />
                          </InputGroupText>
                        </InputGroup>
                        <Label className="text-danger font-size-12">{edErrorMessage}</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} hidden={!isHygeiaChecked}>
                  <Col md="auto" className="pt-4 mt-1">
                    <Label>{""}</Label>
                    <Button
                      disabled={!selectedPlan || !startDate || !endDate}
                      onClick={handleHygeiaAdd}
                      color="primary"
                      className="ms-3"
                      title="Add"
                    >
                      Add
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      onClick={handleHygeiaReset}
                      color="primary"
                      className="ms-3"
                      title="Reset"
                    >
                      Reset
                    </Button>
                    <Button color='primary' title="Cancel" onClick={() => handleHygeiaCancel()}>
                      Cancel
                    </Button>
                  </Col>
                </Col>
              </Row>
              <div hidden={!isHygeiaChecked}>
                <Row>
                  <Col md={2} sm={3} xs={6}>
                    <Label>File Name</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md={2} sm={3} xs={6}>
                    <Label>{selectedFileName}</Label>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col md={3} style={{ marginTop: "27px" }} hidden={isHygeiaChecked}>
                  <Button
                    color="primary"
                    className="ms-3"
                    title="Fetch hygeia claims"
                    onClick={() => setIsHygeia()}
                  >
                    Fetch Hygeia Claims
                  </Button>
                </Col>
              </Row>
            </div>
          );
        }}
      ></Formik>
      {/* <Modal
        isOpen={isOpenHygeia}
        toggle={toggleHygeia}
        size='lg'
        className='xxl'
      >
        <ModalHeader toggle={toggleHygeia}>
          Get Hygeia Claims
        </ModalHeader>
        <ModalBody>
          <HygeiaClaimsSearch />
        </ModalBody>
      </Modal> */}


    </>
  );
};

export default SearchContainer;