import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { includes } from 'lodash';

import Header from './Header';
import Subheader from './Subheader';
import Loader from 'components/Loader';
import StateStore from 'globalStates/Store';
import AppStore from 'context';
import useContextService from 'hooks/useContextService';

const Next = () => {
  const location = useLocation();
  const { states, resetStates } = useContextService();
  const { loading } = states;

  useEffect(() => {
    const { pathname } = new URL(window.location.href);
    const hasPathChanged = !includes(pathname, '/file-source');
    if (hasPathChanged) {
      resetStates('claimsManager');
    }
  }, [location]);

  return (
    <>
      {loading && <Loader opacity={0.01} color='black' />}
      <Outlet />
    </>
  );
};

const Layout = (props) => {
  const { modules } = props;
  return (
    <AppStore>
      <StateStore>
        <section>
          <Header />
          <Subheader modules={modules} />
          <div className='content-wrapper bg-light'>
            <Next />
          </div>
        </section>
      </StateStore>
    </AppStore>
  );
};

export default Layout;
