import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import { isEmpty } from 'lodash';
import ErrorBoundary from '../../components/ErrorBoundary';
import SearchContainer from './SearchContainer';
import FileSourceTable from './FileSourceTable';
import useAxios from '../../hooks/useAxios';
import { onExport, getUpdatedFilter } from '../../utils/helper';

const initialFilterBySearch = {
  // coveredEntityName: '',
  // invoiceStatusName: '',
};

let initialFilters = {
  currentPage: 1,
  pageSize: 10,
  orderBy: 'modifiedDate',
  order: 'desc',
  filters: [],
};

// let selectedCoveredEntity = {};
// let selectedInvoiceStatus = {};


const FileSource = () => {
  const API = useAxios();
  //const [isOpenInvoice, setIsOpenInvoice] = useState(false);
  //const [ceID, setCoveredEntityID] = useState(-1);
  const [state, updateState] = useState({
    initialValues: {},
    data: [],
    //coveredEntity: [],
    loading: false,
    //selectedEntity: '',
    //coveredId: '',
    records: 0,
    reset: false,
  });

  //const [invStartDate, setInvStartDate] = useState(null);
  const setState = (state) => updateState((pre) => ({ ...pre, ...state }));
  const [showTableGrid, setTableGrid] = useState(false);
  let selectedCEId = window.selectedCoveredEntittyId.toString();

  useEffect(() => {
    initialFilters = {
      currentPage: 1,
      pageSize: 10,
      orderBy: 'modifiedDate',
      order: 'desc',
      filters: [],
    };
    setState({ data: [], records: 0 });
    // selectedCoveredEntity = {};
    // selectedInvoiceStatus = {};
    fetchClaimsData(initialFilters);
  }, []);

  const fetchClaimsData = async (pageDetails = {}, hasColumnFilter) => {
    setState({ loading: true });
    let filter = { ...pageDetails };
    if (hasColumnFilter) {
      filter.filters = [...initialFilters.filters, ...pageDetails.filters];
      //initialFilters.filters = [...initialFilters.filters];
    } else {
      initialFilters = { ...initialFilters, ...filter };
    }
    try {
      const { data } = await API.post(
        `/ClaimAnalyzer/getClaimSourceDetails`,
        filter
      );
      if (data) {
        setState({
          data: data.items,
          records: data.totalRecords,
          loading: false,
        });
      } else {
        setState({
          data: [],
          records: 0,
          loading: false,
        });
      }
    } catch (error) {
      setState({ loading: false, data: [], records: 0 });
    }
  };

  const onSearchClaimsData = async (filters) => {
    const { fileSource } = filters;
    initialFilters.filters = [];
    // initialFilters.filters = getUpdatedFilter(
    //   // 'fileSource',
    //   // fileSource,
    //   initialFilters
    // );
    initialFilters.fileSourceId = fileSource
    initialFilters.currentPage = 1;
    fetchClaimsData(initialFilters);
  };


  const onReset = () => {
    initialFilters = {
      currentPage: 1,
      pageSize: 10,
      orderBy: 'modifiedDate',
      order: 'desc',
      filters: [],
    };
    setState({ data: [], reset: !state.reset, records: 0 });
    fetchClaimsData(initialFilters);
  };

  const handleExport = () => {
    const { access_token } =
      JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    const action = `${process.env.REACT_APP_ENDPOINT}/${process.env.REACT_APP_ENDPOINT_POSTFIX}/ClaimAnalyzer/getClaimSourceDetailsExport/?access_token=${access_token}`;
    onExport(action, initialFilters);
  };

  const onPageChange = (filter, hasFilter) => {
    if (hasFilter) {
      fetchClaimsData(filter, true);
    } else {
      fetchClaimsData(filter);
    }
  };

  const handleSearchColumnFilters = async (columnFilters) => {
    await fetchClaimsData({ ...initialFilters, ...columnFilters }, true);
  };

  // const handleUpdateEntity = (entity) => {
  //   selectedCoveredEntity = entity;
  // };
  const handleTableGrid = (val) => {
    setTableGrid(val);
  }

  const handleInvoiceStatus = (invoiceStatus) => {
    selectedInvoiceStatus = invoiceStatus;
  };

  const refetch = (hasReset) => {
    if (hasReset) {
      initialFilters = {
        currentPage: 1,
        pageSize: 10,
        orderBy: 'modifiedDate',
        order: 'desc',
        filters: [],
      };
    }
    fetchClaimsData(initialFilters);
  };


  // const onHandleManualInvoice = () => {
  //   setIsOpenInvoice(!isOpenInvoice);
  // }

  return (
    <div>
      <Container fluid className='my-3'>
        <Card className='border-0 shadow-top'>
          <CardBody className='section-devider-border'>
            <ErrorBoundary>
              <SearchContainer
                initialValue={initialFilterBySearch}
                onSearch={onSearchClaimsData}
                onReset={onReset}
                // handleUpdateEntity={handleUpdateEntity}
                // handleInvoiceStatus={handleInvoiceStatus}
                // selectedCEId={selectedCEId}
                showTableGrid={handleTableGrid}
                refetch={refetch}
              />
            </ErrorBoundary>
          </CardBody>
          <CardBody className='pt-0' hidden={showTableGrid}>
            <ErrorBoundary>
              <FileSourceTable
                data={state.data}
                //loading={state.loading}
                onExport={handleExport}
                filters={initialFilters}
                totalRecords={state.records}
                onPageChange={onPageChange}
                // selectedCoveredEntity={selectedCoveredEntity}
                // coveredEntityID={selectedCoveredEntity.coveredEntityID}
                // tenantID={selectedCoveredEntity.tenantID}
                //setLoading={(value) => setState({ loading: value })}
                refetch={refetch}
                onSearchColumnFilters={handleSearchColumnFilters}
                reset={state.reset}
              />
            </ErrorBoundary>
          </CardBody>
        </Card>
      </Container>

    </div>
  );
};

export default FileSource;